body,
html {
 * {
    margin: 0;
    padding: 0;
  }
  
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: $font-size;

  @media screen and (min-width: 768px) { 
    $calculated-factor: pow($font-size-responsive-factor, 2); //functions within a calc don't work

    font-size: calc(#{$font-size} * #{$calculated-factor});
  }

  background-color: $anti-primary;

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: currentColor;

    &:focus { 
        outline: none;
    }

    &:hover {
        opacity: .7;
      }

    &:active {
      opacity: .4;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .4;
    }
  }

  a {
    color: currentColor;

    &:hover {
      opacity: .7;
    }
  }

  h1 {
    font-size: $font-size--l5;
    font-weight: $font-weight--600;
    letter-spacing: -0.043rem;
    line-height: $line-height--l5;
  }
  
  h2 {
    font-size: $font-size--l4;
    font-weight: $font-weight--500;
    letter-spacing: -0.024rem;
    line-height: $line-height--l4;
  }
  
  h3 {
    font-size: $font-size--l2;
    font-weight: $font-weight--500;
    letter-spacing: -0.016rem;
    line-height: $line-height--l2;
  }
  
  h4 {
    font-size: $font-size--l1;
    font-weight: $font-weight--500;
    letter-spacing: -0.007rem;
    line-height: $line-height--l2;
  }
  
  h5 {
    font-size: $font-size--base;
    font-weight: $font-weight--300;
    letter-spacing: 0.009rem;
    line-height: $line-height--base;
  }
  
  h6 {
    font-size: $font-size--s2;
    font-weight: $font-weight--400;
    letter-spacing: 0.015rem;
    line-height: $line-height--base;
  }
  
  p,
  span {
    font-size: $font-size--base;
    font-weight: $font-weight--300;
    line-height: $line-height--l2;
  
    &.emphasize {
      font-size: inherit;
      font-weight: $font-weight--900;
      line-height: $line-height--l1;
      color: $secondary-darker;
    }
  }

  ol {
    padding: .5rem 0;
  }
  li {
    margin-left: 2rem;
    padding: 0 .5rem;
  }
  
  .base {
    overflow-y: scroll;
    overflow-x: hidden;

    height: 100%;
    width: 100%;

    .spinner {
      position: fixed;
      top: 45%;
      left: 45%;
    }

    .animate {
      &--rtl {
          @include slide-to-left;
      }

      &--pulse {
        @include pulse;
      }

      &--ltr {
        @include slide-to-right;
      }

      &--sou {
        @include slide-out-up;
      }

      &--sI {
        @include scale-in;
      }

      &--fade-in {
        @include fade-in;
      }
    }

  .controls {
    position: fixed;
    z-index: 10;
    height: auto;
    width: auto;
    display: flex;

    &__scrollToTop,
    &__link,
    &__attribution {
      &:hover &:not(:disabled) {
        fill: $secondary-darker;
        opacity: 1;
      }

      fill: $secondary-dark;
      height: 45px;
      width: 45px;
      border-radius: 25px;
    }

    &__attribution {
      &--popout {
        margin-top: .5rem;
        box-shadow: 0 0 5px 1px rgba(144, 144, 137, .5);
        border-radius: .5rem;
        color: $secondary-light;
        background-color: $secondary-darker;
        padding: 1rem;
        position: relative;
        z-index: 200;

        a {
          &:hover {
            opacity: .5;
            color: currentColor;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: -0.3rem;
          left: 15px;
          height: 1rem;
          width: 1rem;
          color: $secondary-light;
          background-color: $secondary-darker;
          transform: rotate(45deg);
        }
      }
    }
  }

  .logo {
    position: fixed;
    top: 25px;
    right: 25px;
    fill: $secondary-dark;
    
    &:hover {
      fill: $blue-dark;
      opacity: 1;
    }

  }

  .noselect {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }
}

  .app {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $primary;

    .fade-out.aos-animate {
      opacity: 0;
    }
  }
}