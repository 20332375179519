.page {
  width: 100vw;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 50px auto 50px;
  position: relative;

  &__container {
    grid-row: 2 / 3;
    min-width: 400px;
    width: 80vw;
    height: 80vh;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &__navigation {
    color: $secondary-darker;
    position: absolute;
    bottom: 2.5rem;
    justify-self: center;
    text-align: center;
    border-top: 1px solid $secondary;
    max-width: 50vw;
    
    p {
      font-size: $font-size--l5;
    }
  }

  &__two {
    width: 100%;
    
    &--epi {
      margin-top: 2rem;
      display: grid;
      grid-template-rows: .5fr 3fr .5fr;
      row-gap: 1rem;
      padding: 1rem;
      border-radius: .5rem;
      background-color: $secondary-light;
    }

    &--title {
      border-bottom: 1px solid currentColor;
      grid-row: 1 / 2;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &--text {
      grid-row: 2 / 3;
    }

    &--attribution {
      grid-row: 3 / -1;
      justify-self: center;
      align-self: flex-end;
    }
  }
}