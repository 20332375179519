.accordion {
  display: block;
  position: absolute;
  overflow-x: auto;
  border-top: 1px solid $primary-light;

  &__content {
    display: flex;
  }
  
  &__section {
    background-color: $primary-light;
    height: 100%;
    padding: .2rem .5rem;
    border-right: 1px solid $primary-light;
    color: $secondary-dark;
    text-transform: uppercase;

    &:first-child {
      border-left: 1px solid $primary-light;
    }

    &:last-child {
      border-right: none;
    }
  }
  
  &__option {
    height: 100%;
    min-width: max-content;
    padding: .2rem .5rem;
    color: $secondary-darker;
    background-color: $primary-light;
    border-bottom: 1px solid $primary-light;

    &--selected {
      color: $blue-dark;
      box-shadow: inset 0 0 2px 1px $blue-dark;
    }

    &:disabled {
      color: $secondary--transparent;
      background-color: $secondary-dark;
      opacity: 1;
    }

    &:last-child {
      border-right: 1px solid $primary-light;
    }
  }

  &__scrollbar {
    background-color: transparent;
  }
}