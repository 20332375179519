.dialog {
    padding: 1rem;
    color: $secondary-light;
    border-radius: .1rem;
    width: max-content;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-self: center;
    background-color: $primary-light;
    z-index: 50;

    &__title {
      padding-bottom: .5rem;
      width: 100%;
      text-align: center;
      margin-bottom: .5rem;
      border-bottom: 1px solid $primary-light;
    }
    &__content {
      margin-top: 1.5rem;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, auto));
      grid-template-rows: repeat(auto, max-content);
      column-gap: 1rem;
      row-gap: 1rem;
      align-items: center;
      margin-bottom: .5rem;
  }
}