@keyframes fade-in {
  0%   { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes fade-in {
  0%   { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes fade-in {
  0%   { opacity:0; }
  100% { opacity:1; }
}

.scatterplot {
  justify-content: center;
  overflow: hidden;

  .canvas {
    position: relative;

    .tooltip {
      position: absolute;
      font-size: .8rem;
      padding: .1rem .2rem;
      width: auto;
      height: auto;
      pointer-events: none;
      color: $secondary-light;
      transform: translateX(-50%);
      border-radius: .2rem;
      background-color: $primary-light;;
    }

    &__chevron {
      position: absolute;
      top: 45%;
      z-index: 10;
      right: 5%;
    }

    &__indicator {
      box-sizing: border-box;
      position: absolute;
      padding: 1rem;
      background-color: $secondary--transparent;
      margin: 10px;
      border-radius: .5rem;
      transition: all 1s ease;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--description {
        color: $primary;

        span.emphasize {
          color: $secondary-darker;
        }
      }
      
      &--source {
        margin-top: .5rem;
      }

      .emphasize {
        font-weight: bold;
        color: $secondary-darker;
      }
    }

    .noData {
      position: absolute;
      justify-self: center;
      top: 45%;
      left: 40%;
      padding: 1rem;
      color: $primary-light;
      animation: fade-in 2s ease;
    }
  }  

  &__xAxis--label {
    justify-content: center;
    display: flex;
  }
}