.tip {
  @keyframes fadein {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  position: absolute;
  display: flex;
  justify-content: center;

  &__backdrop {
    background-color: rgba(0, 0, 0, .5);
    z-index: 500;
    position: absolute;
    width: 100vw;
    height: 110vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.4s;
    will-change: opacity;
  }

  &__content {
    width: 40vw;
    max-width: 600px;
    background-color: $secondary-light;
    padding: 1rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 100;
    -webkit-box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 13px -3px rgba(0,0,0,0.75);
  }
  
  &__controls {
    margin-top: 1rem;
    align-self: flex-end;
    
    &--cancel {
      font-size: 1rem;
      padding: .5rem;
      border: 1px solid $primary-light;
      border-radius: .2rem;
      color: $primary-light;
    }
    
    &--confirm {
      font-size: 1rem;
      padding: .5rem;
      border: 1px solid $primary-light;
      border-radius: .2rem;
      background-color: $primary-light;
      margin-left: .5rem;
      color: $secondary-light;
    }
  }
}