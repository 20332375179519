.line {
  height: 100%;
  width: 100%;

  display: grid;
  align-items: center;
  grid-template-areas: 
    'text text'
    'canvas right';
  grid-template-columns: auto 1px;
  
  &__description {
    grid-area: text;
  }

  &__canvas {
    grid-area: canvas;
    position: relative;

    .xAxis {
      width: max-content;
      color: $primary-light;
      font-size: 3rem; // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
      text-transform: uppercase;
      position: absolute;
    }
  }
  
  .popup {
    grid-area: canvas;
    border-radius: .1rem;
    padding: 1rem;
    color: $secondary-light;
    background-color: $primary-light;
    justify-self: center;
    position: relative;
    z-index: 10;
  }
}