.chart {
  min-width: 400px;
  width: 80vw;
  max-width: 800px;
  grid-template-rows: minmax(0, auto) 40px;
  color: $primary-light;
  display: grid;
  justify-content: center;
  align-items: center;

  &__control {
    display: flex;
    justify-content: center;
    color: $secondary-darker;
    position: absolute;
    
    &--year, &--action {
      font-size: $font-size--l3;
      font-weight: $font-weight--600;
      border: 2px solid currentColor;
    }

    &--year {
      border-top-left-radius: .5rem;
      border-bottom-left-radius: .5rem;
      padding: .5rem 1rem;
      width: 100px;

      :hover {
        background-color: $secondary--transparent;
        // opacity: .2;
        color: $secondary-darker;
      }

      > button {
        height: 50px;
      }

      &.looping {
        background-color: $secondary-darker;
        color: $secondary-light;
        border: 2px solid $secondary-darker;
      }

      &.open {
        padding: 0rem;
        display: flex;
        flex-direction: column;
        border: none;
        
        button {
          font-size: $font-size--l3;
          padding-top: .5rem;
          padding-bottom: .5rem;
          border-left: 2px solid currentColor;
          border-right: 2px solid currentColor;
        }

        .selected {
          background-color: $secondary-darker;
          color: $secondary-light;
          border-color: $secondary-darker;
        }

        :first-child {
          border-top: 2px solid currentColor;
          border-top-left-radius: .5rem;
          border-top-right-radius: .5rem;
          &.selected {
            border-top-color: $secondary-darker;
          }
        }
        :last-child {
          border-bottom: 2px solid currentColor;
          border-bottom-left-radius: .5rem;
          &.selected {
            border-color: $secondary-darker;
            border-top: 2px solid $secondary-darker;
          }
        }
      }
    }

    &--action {
      border-top-right-radius: .5rem;
      border-bottom-right-radius: .5rem;
      display: flex;
      align-items: center;
      height: max-content;
      align-self: flex-end;
      border-left: none;
      padding: .5rem;
      
      &.looping, &:hover {
        background-color: $secondary-darker;
        border: 2px solid $secondary-darker;

        svg {
          fill: $secondary-light;
        }
      }

      &:hover {
        opacity: .7;
      }
      
      &.disabled {
        color: $secondary--transparent;
        pointer-events: none;
        svg {
          fill: $secondary--transparent;
        }
      }
    }
  }

  .scatterplot, .lollipop {
    grid-row: 1 / 2;
  }

  &--yAxis {
      margin: 0;
      text-align: center;
      display: block;
      padding: 0;
      grid-row: 2 / 3;
      align-self: center;
      justify-self: center;
  }

  &__line {
    display: grid;
    grid-template-columns: 20px minmax(0, auto);
    grid-template-rows: .5fr 2fr;
  
    &--description {
      grid-row: 1 / 2;
      grid-column: 1 / -1;
    }
    
    .line {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
  
    &--yAxis {
      grid-row: 2 / 3;
      transform: rotate(-90deg);
      grid-column: 1 / 2;
      height: auto;
      width: 400px;
      text-align: center;
      align-self: center;
      justify-self: center;
    }
  }
}
