.indicatorSelectionPage {
  width: 100vw;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 4rem minmax(0, auto) 50px;
  position: relative;

  &__container {
    grid-row: 2 / 3;
    min-width: 400px;
    width: 80vw;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: $font-size--l1;
    }
  }

  &__selector {
    grid-row: 1 / 2;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
  }

  .react-select {
    &__container {
      height: 100%t;
      width: 100%;
      font-size: $font-size--l4;
      font-weight: $font-weight--400;
      letter-spacing: -0.007rem;
      line-height: $line-height--l2;
      border-bottom: 2px solid $secondary-darker;
    }
    
    &__control {
      width: 100%;
      background-color: transparent;
      border: none;
      position: relative;
      color: $secondary-darker;
      box-shadow: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &:hover {
        background-color: $secondary;
        .react-select__placeholder, .react-select__clear-indicator {
          color: $anti-primary;
        }
      }
    }

    &--is-disabled {
      .react-select__placeholder {
        color: $primary-light;
      }
      &.react-select__container {
        border-bottom: 2px solid $primary-light;
      }
    }

    &__indicator-separator {
      background-color: $anti-primary;
    }
    
    &__dropdown-indicator {
      color: $anti-primary;
    }
    
    &__single-value {
      color: $secondary-darker;
      overflow: visible;
      font-weight: $font-weight--500;

      &--is-disabled {
        color: $primary-light !important;
      }
    }

    &__placeholder {
      font-weight: $font-weight--500;
    }

    &__value-container {
      display: flex;
      justify-content: center;
      width: 100%;
      position: absolute;

      > * {
        color: $secondary-darker;
      }
    }

    &__indicators {
      position: absolute;
      right: 0;
    }

    &__menu {
      color: $secondary-darker;
      background-color: $secondary-light;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
  
  &__navigation {
    border-top: 1px solid $secondary;
    background-color: $secondary-darker;
    border-radius: .2rem;
    padding: .5rem;
    color: $secondary-light;
    position: absolute;
    bottom: 2.5rem;
    justify-self: center;
    text-align: center;
    
    & .emphasize {
      color: $anti-primary;
    }

    p {
      font-size: $font-size--l5;
    }
  }
}
     