/** COLORS */
$primary: #000000;
$primary-light: #555555;
$primary-light--transparent: rgba(85, 85, 85, .55);
$secondary: #BCCFD6;
$secondary--transparent: rgba(188, 207, 214, .70);
$secondary-dark: #B8D0D7;
$secondary-darker: #73A3B1;
$secondary-light: #F3F3F3;
$blue: #00467F;
$blue-dark: #012E52;
$blue-dark--transparent: rgba(1, 46, 82, .75);
$blue-light: #8FC3E9;
$blue-lighter: #C2E2F7;
$anti-primary: #FFFFFF;

/** TYPOGRAPHY */
$font-size: 14px;
$font-size-responsive-factor: 1.067;

$font-size--s3: 0.702rem;
$font-size--s2: 0.79rem;
$font-size--s1: 0.889rem;
$font-size--base: 1rem;
$font-size--l1: 1.125rem;
$font-size--l2: 1.266rem;
$font-size--l3: 1.424rem;
$font-size--l4: 1.602rem;
$font-size--l5: 1.802rem;

$line-height--s1: 0.75rem;
$line-height--base: 1rem;
$line-height--l1: 1.25rem;
$line-height--l2: 1.5rem;
$line-height--l3: 1.75rem;
$line-height--l4: 2rem;
$line-height--l5: 2.25rem;

$font-weight--100: 100;
$font-weight--200: 200;
$font-weight--300: 300;
$font-weight--400: 400;
$font-weight--500: 500;
$font-weight--600: 600;
$font-weight--700: 700;
$font-weight--800: 800;
$font-weight--900: 900;