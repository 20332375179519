.lollipop {
  display: grid;
  grid-template-areas: 
    'canvas right';
  grid-template-columns: auto 1px;
  align-items: center;

  .canvas {
    grid-area: canvas;
    position: relative;
  }
}