@import './transitions';

@mixin slide-to-left {
  -webkit-animation: slide-to-left .7s ease-out;
  -moz-animation: slide-to-left .7s ease-out;
   animation: slide-to-left .7s ease-out;
}

@mixin slide-to-right {
  -webkit-animation: slide-to-right .7s ease;
  -moz-animation: slide-to-right .7s ease;
   animation: slide-to-right .7s ease;
}

@mixin slide-out-up {
  -webkit-animation: slide-out-up .7s ease;
  -moz-animation: slide-out-up .7s ease;
   animation: slide-out-up .7s ease;
}

@mixin pulse {
  -webkit-animation: pulse 3s infinite;
  -moz-animation: pulse 3s infinite;
   animation: pulse 3s infinite;
}

@mixin fade-in {
  -webkit-animation: fade-in 3s ease;
  -moz-animation: fade-in 3s ease;
   animation: fade-in 3s ease;
}

@mixin scale-in {
  -webkit-animation: scale-in 1s linear;
  -moz-animation: scale-in 1s linear;
   animation: scale-in 1s linear;
}