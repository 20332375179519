.ticker {
  @keyframes pulse {
    0%   { opacity: 0; }
    50%  { opacity: 1; }
    100% { opacity: 0; }
  }
  @-moz-keyframes pulse {
    0%   { opacity: 0; }
    50%  { opacity: 1; }
    100% { opacity: 0; }
  }
  @-webkit-keyframes pulse {
    0%   { opacity: 0; }
    50%  { opacity: 1; }
    100% { opacity: 0; }
  }
  
  @mixin pulse {
    -webkit-animation: pulse 2s infinite;
    -moz-animation: pulse 2s infinite;
     animation: pulse 2s infinite;
  }

  @include pulse;
}