@keyframes slide-to-left {
  0% { transform: translateX(100%); }   
  100% { transform: translateX(0); }
}
@-webkit-keyframes slide-to-left {
  0% { -webkit-transform: translateX(100%); }   
  100% { -webkit-transform: translateX(0); }
}
@-moz-keyframes slide-to-left {
  0% { -moz-transform: translateX(100%); }   
  100% { -moz-transform: translateX(0); }
}

@keyframes pulse {
  0%   { opacity:1; }
  50%  { opacity:.3; }
  100% { opacity:1; }
}
@-moz-keyframes pulse {
  0%   { opacity:1; }
  50%  { opacity:.3; }
  100% { opacity:1; }
}
@-webkit-keyframes pulse {
  0%   { opacity:1; }
  50%  { opacity:.3; }
  100% { opacity:1; }
}

@keyframes slide-to-right {
  0% { transform: translateX(0%); }   
  100% { transform: translateX(120%); }
}
@-webkit-keyframes slide-to-right {
  0% { -webkit-transform: translateX(0%); }   
  100% { -webkit-transform: translateX(120%); }
}
@-moz-keyframes slide-to-right {
  0% { -moz-transform: translateX(0%); }   
  100% { -moz-transform: translateX(120%); }
}

@keyframes slide-out-up {
  0% { transform: translateY(0%); }   
  100% { transform: translateY(-100%); }
}
@-webkit-keyframes slide-out-up {
  0% { -webkit-transform: translateY(0%); }   
  100% { -webkit-transform: translateY(-100%); }
}
@-moz-keyframes slide-out-up {
  0% { -moz-transform: translateY(0%); }   
  100% { -moz-transform: translateY(-100%); }
}

@keyframes scale-in {
  0% { transform: scale(0); }   
  20% { transform: scale(0); }
  70% { transform: scale(100%); }
  100% { transform: scale(100%); }
}
@-webkit-keyframes scale-in {
  0% { -webkit-transform: scale(0); }   
  20% { -webkit-transform: scale(0); }
  70% { -webkit-transform: scale(100%); }
  100% { -webkit-transform: scale(100%); }
}
@-moz-keyframes scale-in {
  0% { -moz-transform: scale(0); }   
  20% { -moz-transform: scale(0); }
  70% { -moz-transform: scale(100%); }
  100% { -moz-transform: scale(100%); }
}

@keyframes fade-in {
  0%   { opacity:0; }
  50%  { opacity:.3; }
  100% { opacity:1; }
}
@-moz-keyframes fade-in {
  0%   { opacity:0; }
  50%  { opacity:.3; }
  100% { opacity:1; }
}
@-webkit-keyframes fade-in {
  0%   { opacity:0; }
  50%  { opacity:.3; }
  100% { opacity:1; }
}
